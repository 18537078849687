import React from "react";

function Roadmap() {
  return (
    <section id="roadmap">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 border-t border-white/25">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-12">
            <h2 className="h2 mb-4 text-gray-50">Our roadmap</h2>
          </div>

          {/* Items */}
          <div className="max-w-3xl mx-auto -my-4 md:-my-6" data-aos-id-timeline>
            {/* 1st item */}
            <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]">
              <div className="pl-2">
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-indigo-300 bg-indigo-800 rounded-full">
                    0%
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-400 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-fuchsia-500 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4 text-gray-50">Collection Launch</h4>
                </div>
                <p className="text-lg text-gray-200">
                  The Women Tribe collection will be available to mint here on our website. The presale will begin on
                  February 15th and the public sale will begin on February 19th.
                </p>
              </div>
            </div>

            {/* 2nd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-indigo-300 bg-indigo-800 rounded-full">
                    20%
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-400 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-fuchsia-500 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4 text-gray-50">Charity Donation</h4>
                </div>
                <p className="text-lg text-gray-200">
                  We will be donating 5% of primary sales to OutRight International’s EmpowHER program. To learn more
                  about why we chose OutRight and about the program, please read our Medium article.
                </p>
              </div>
            </div>

            {/* 3rd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-indigo-300 bg-indigo-800 rounded-full">
                    40%
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-400 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-fuchsia-500 border-4 box-content border-gray-800 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4 text-gray-50">Community Wallet</h4>
                </div>
                <p className="text-lg text-gray-200">
                  The Women Tribe Community Wallet is open and deposited with 10 ETH.
                </p>
              </div>
            </div>

            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-indigo-300 bg-indigo-800 rounded-full">
                    60%
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-400 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-fuchsia-500 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4 text-gray-50">Merch Website</h4>
                </div>
                <p className="text-lg text-gray-200">
                  Awesome merch for Women Tribe will now be available! You can represent Women Tribe IRL with beautiful
                  merch designed by Izzy.
                </p>
              </div>
            </div>

            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-indigo-300 bg-indigo-800 rounded-full">
                    80%
                  </div>
                  <div
                    className="absolute left-0 h-full px-px bg-gray-400 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-fuchsia-500 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4 text-gray-50">App Development & Scholarship Program</h4>
                </div>
                <p className="text-lg text-gray-200">
                  We will start developing the Women Tribe App. We’ll also announce details for the Women Tribe
                  Scholarship Program and one of the holders who applies will receive 5 ETH.
                </p>
                <p className="text-lg text-gray-200">
                  We will be donating 5% of primary sales to a second charity (to be announced soon).
                </p>
              </div>
            </div>

            {/* 4th item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-indigo-300 bg-indigo-800 rounded-full">
                    100%
                  </div>
                  <div
                    className="absolute left-0 w-2 h-2 bg-fuchsia-500 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                    aria-hidden="true"
                  ></div>
                  <h4 className="h4 text-gray-50">Now Commencing Season 2</h4>
                </div>
                <p className="text-lg text-gray-200">
                  The Women Tribe Team will start working on season 2 with special airdrops available to holders and
                  exclusive giveaways.
                </p>
                <p className="mt-4 text-lg text-gray-200">
                  We’ll also host a giveaway in our Discord for special holders (who own 4+ Women Tribe NFTs) to win an
                  all-expenses paid trip to a special destination, chosen by our holders. We believe travel is one of
                  the best ways to grow in both our personal and professional lives, so we’re excited to award this
                  experience to our community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
