import React from "react";

function Faqs() {
  return (
    <section name="faq" id="faq">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h2 className="h2 text-gray-50">Frequently asked questions</h2>
          </div>

          {/* Faqs */}
          <ul className="grid gap-8 md:grid-cols-2 xl:gap-x-16 lg:gap-y-12">
            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">How were the Women Tribe NFTs created?</h4>
              <p className="text-base text-gray-200">
                Each Woman Tribe was programmatically generated from over 900 hand-drawn traits, created by Izzy.
              </p>
            </li>

            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">When is the launch happening?</h4>
              <p className="text-base text-gray-200">
                The presale will be on February 15th and public sale on February 19th.
              </p>
            </li>

            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">
                How do I become a Verified Tribe Member in Discord?
              </h4>
              <p className="text-base text-gray-200">
                If you have an NFT from the Women Tribe collection in your Metamask wallet, head over to #verify-woman
                and type: !join
              </p>
            </li>

            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">How do I get on the Presale?</h4>
              <p className="text-base text-gray-200">
                We believe in rewarding the community. Presale is open to active members of the community and for those
                who have supported us from the start. Also, we will be having giveaways in which contestants can win a
                spot on the presale list.
              </p>
            </li>

            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">How do I get a WT?</h4>
              <p className="text-base text-gray-200">
                As soon as the collection is live, you can mint them directly from our website. Once sold out, they can
                be purchased on the secondary markets, like OpenSea.
              </p>
            </li>

            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">What is the mint price?</h4>
              <p className="text-base text-gray-200">The price to mint 1 Woman Tribe NFT is 0.04 ETH.</p>
            </li>

            <li>
              <h4 className="text-xl font-semibold mb-2 text-gray-50">Are there any rare 1/1s in the collection?</h4>
              <p className="text-base text-gray-200">
                Among the 10,000, there will be 10 rare 1/1s which will feature 30 second tracks. We will donate 100% of
                secondary sales' royalties from these 10 to a charity previously decided by each musician, making it a
                total of 10 charities.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
