import React from "react";

function Team() {
  return (
    <section id="team">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-16">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-12">
            <h2 className="h2 mb-4 text-gray-50">Our team</h2>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>
            {/* Izzy */}
            <div
              className="sm:w-1 lg:w-1/2 py-4 sm:py-8 sm:px-3"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team/izzy.jpg").default}
                  width="120"
                  height="120"
                  alt=""
                />
                <h4 className="text-xl font-semibold mb-1">Izzy, Founder & Artist</h4>

                <a
                  className="inline-block text-pink-200 hover:text-white font-semibold transition duration-150 ease-in-out"
                  href="https://twitter.com/nft_izzy"
                >
                  @nft_izzy
                </a>

                <div className="text-sm text-gray-200 text-center mt-2">
                  <p>
                    My name is Izzy, short for Isabel, and I am a 22 year old girl from Portugal. My two passions are
                    music production and art. Here's my story:
                  </p>
                  <p className="mt-2">
                    Back in late 2019, I had the worst time of my life. Everything that could've gone wrong, did. I
                    found out I had to go under medical intervention due to a problem in my heart (SVT) and I couldn't
                    work for over a year because my heart would literally go from 80 bpms to 250bpms in one second. It
                    was a horrible time of my life and I was terrified. In July 2020, I got the intervention and my
                    heart was treated. I still couldn't work for 3 more months. During this year, I developed serious
                    anxiety and panic attacks (something I still go through nowadays). I used to be such an independent
                    person. I could go anywhere in the world by myself and, suddenly, I couldn't even get on the bus.
                  </p>
                  <p className="mt-2">
                    There was nothing I could do, so I started learning about crypto and, even as a newbie, I made some
                    money with it. So, I was happy. From there, I found out about the NFT community and instantly fell
                    in love with it. It was an unknown world to me but a very exciting one. I watched tons of YouTube
                    videos and went to hundreds of Twitter Spaces. Finally, I decided I wanted to be a part of this.
                  </p>
                  <p className="mt-2">
                    That's how I created Women Tribe, a project inspired by and dedicated to Women around the world. I
                    was lucky enough to get the best team one could ask for and the most wonderful community around the
                    project. I'm excited for us and I'm excited about the future!
                  </p>
                </div>
              </div>
            </div>

            {/* Jashon */}
            <div
              className="sm:w-1 lg:w-1/2 py-4 sm:py-8 sm:px-3"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team/jashon.jpg").default}
                  width="120"
                  height="120"
                  alt=""
                />
                <h4 className="text-xl font-semibold mb-1">Jashon, Project Manager</h4>

                <a
                  className="inline-block text-pink-200 hover:text-white font-semibold transition duration-150 ease-in-out"
                  href="https://twitter.com/jashonmills"
                >
                  @jashonmills
                </a>

                <div className="text-sm text-gray-200 text-center mt-2">
                  <p>
                    I have been involved in cryptocurrency since late 2017 and NFTs since July 2021. My wife, Alissa,
                    and I owned and operated a successful upholstery shop in Houston, TX for over a decade. We have an 8
                    year old son, who thrives with autism, and 2 dogs.
                  </p>
                  <p className="mt-2">
                    In 2017, I took a gamble with crypto as a chance to give us a leg up on life, which has now allowed
                    us to be fully retired. With our newfound freedom, we have set out on the road to travel the United
                    States for the past two years attending crypto/NFT events. Through supporting my wife in her NFT
                    endeavors, I found that women artists and creators are very disproportionately given equal
                    opportunity in this space. So I decided to start personally supporting and promoting women
                    artists/creators. In my time doing so, I have found that the hardest working people in this space
                    are women, so I'm honored to have been offered the opportunity to join the Women Tribe team and be
                    surrounded by so many extraordinary women.
                  </p>
                </div>
              </div>
            </div>

            {/* Emm */}
            <div
              className="sm:w-1 lg:w-1/2 py-4 sm:py-8 sm:px-3"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team/emm.jpg").default}
                  width="120"
                  height="120"
                  alt="Emm"
                />
                <h4 className="text-xl font-semibold mb-1">Emm, Advisor</h4>

                <a
                  className="inline-block text-pink-200 hover:text-white font-semibold transition duration-150 ease-in-out"
                  href="https://twitter.com/NFTs_girl"
                >
                  @NFTs_girl
                </a>

                <div className="text-sm text-gray-200 text-center mt-2">
                  <p>
                    I’m currently the CMO at Crypto Chicks, while working on my own IRL tech startup as Co-Founder/CEO.
                    I grew up in the Midwest and currently live in San Francisco (on paper, technically - in reality,
                    I’m doing a bit of digital nomad life and traveling around the country). Previously, I led Business
                    Operations at a Silicon Valley startup and worked for Visa (yikes, opposite of blockchain, I know).
                  </p>
                  <p className="mt-2">
                    I’m a dog mom who loves all outdoor activities - hiking, biking, skiing, and swimming (to name a
                    few). I’m passionate about bringing more women into tech, crypto, and NFTs. I’m so honored to help
                    an incredible artist and woman like Izzy in her journey to create her collection of NFTs.
                  </p>
                </div>
              </div>
            </div>

            {/* Alissa */}
            {/* <div
              className="sm:w-1 lg:w-1/2 py-4 sm:py-8 sm:px-3"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team/alissa.jpg").default}
                  width="120"
                  height="120"
                  alt=""
                />
                <h4 className="text-xl font-semibold mb-1">Alissa, Moderator</h4>

                <a
                  className="inline-block text-pink-200 hover:text-white font-semibold transition duration-150 ease-in-out"
                  href="https://twitter.com/AlissaMills_"
                >
                  @AlissaMills_
                </a>

                <div className="text-sm text-gray-200 text-center mt-2">
                  <p>
                    I grew up in Southern California, USA and have lived all up and down the West Coast. I owned and ran
                    an Upholstery Business in Texas for over a decade with my husband, Jashon. I’m a passionate autism
                    advocate and now spend my days with my family traveling the country due to cryptocurrency providing
                    me financial security. I’m proud to be a woman in crypto and NFTs. Bringing other women along for
                    the ride is the only way to travel and I’m proud to support Izzy on this journey
                  </p>
                </div>
              </div>
            </div> */}

            {/*
            <div
              className="sm:w-1 lg:w-1/2 py-4 sm:py-8 sm:px-3"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-team]"
            >
              {/* <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team/madi.jpg").default}
                  width="120"
                  height="120"
                  alt=""
                />
                <h4 className="text-xl font-semibold mb-1">Madi, Community & Operations Manager</h4>

                <a
                  className="inline-block text-pink-200 hover:text-white font-semibold transition duration-150 ease-in-out"
                  href="https://twitter.com/not_madi_"
                >
                  @not_madi_
                </a>

                <div className="text-sm text-gray-200 text-center mt-2">
                  <p>
                    I grew up on the East Coast before moving to Seattle a few years ago - and I’ll never go back! I
                    spend my time outside of work paddleboarding in the summer, skiing in the winter, and playing with
                    my dog all year round. In the real world, I am a Program Manager at Facebook/Meta, with previous
                    experience in Learning & Development at Google Cloud and in the customer care world for Google Ads.
                    From a young age, I was super passionate about women’s education initiatives and that has translated
                    in my career to supporting organizations that bring more women into tech - and lately web3! I
                    stumbled across Women Tribe and Izzy while endlessly scrolling Twitter, and I feel so lucky to have
                    found such a compassionate and talented soul like Izzy combined with a project so aligned with my
                    values.
                  </p>
                </div>
              </div>
            </div> */}

            {/* Martin */}
            <div
              className="sm:w-1 lg:w-1/2 py-4 sm:py-8 sm:px-3"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-team]"
            >
              <div className="flex flex-col items-center">
                <img
                  className="rounded-full mb-4"
                  src={require("../images/team/martin.jpg").default}
                  width="120"
                  height="120"
                  alt="Emm"
                />
                <h4 className="text-xl font-semibold mb-1">Martin, Community Manager</h4>

                <a
                  className="inline-block text-pink-200 hover:text-white font-semibold transition duration-150 ease-in-out"
                  href="https://twitter.com/Crewefan87"
                >
                  @Crewefan87
                </a>

                <div className="text-sm text-gray-200 text-center mt-2">
                  <p>
                    I’m originally from England, but spent most of my childhood in Germany due to a military upbringing.
                    I currently live in Australia, which is much warmer and has loads more beaches! Football (or soccer
                    depending where you’re from) is my main hobby and my first true love. Chasing waterfalls and hiking
                    through the rainforest is a close second! I’ve been in crypto since 2018, soon realized that this
                    was the future and began absorbing as much knowledge as I could. Recently I began exploring NFT’s
                    and I stumbled across Izzy’s art. Needless to say, I was a fan immediately! I’m currently studying
                    blockchain and coding to expand my knowledge, with the aim to eventually work in this space full
                    time. I’m honored to be a part of Izzy’s team and this project!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
