import React from "react";

function Mission() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="h2 mb-4 text-gray-50">Our Mission</h2>
            <p className="text-xl text-gray-50">
              While our roadmap is a guideline for our plans regarding our first season, our ultimate goal is to help
              women and all people embrace their inner divinity and power. Some of our long term highlights to
              accomplish over the next years include:
            </p>
            <p className="mt-6 text-xl text-gray-200">
              <strong>Scholarship & Charity</strong>: Establishing and funding a yearly scholarship program to help
              women in need attend a school of their choice, as well as donating to charities that resonate with our
              values.
            </p>
            <p className="mt-6 text-xl text-gray-200">
              <strong>Epic Events</strong>: Exclusive epic events on a yearly basis that will allow holders to
              experience once-in-a-lifetime opportunities.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mission;
