import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Women Tribe">
              <img
                alt="Women Tribe logo"
                className="inline-block"
                src={require("../images/Logo-big.png").default}
                width={160}
              />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop menu links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <a
                  href="#roadmap"
                  className="text-gray-50 font-medium hover:text-white px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Roadmap
                </a>
              </li>
              <li>
                <a
                  href="#team"
                  className="text-gray-50 font-medium hover:text-white px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  href="#faq"
                  className="text-gray-50 font-medium hover:text-white px-4 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  FAQ
                </a>
              </li>
            </ul>

            {/* Desktop sign in links */}
            {/* <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <Link to="/signup" className="btn-sm text-white bg-fuchsia-600 hover:bg-fuchsia-700 ml-3">
                  Connect your wallet
                </Link>
              </li>
            </ul> */}
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
