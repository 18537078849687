import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="py-8 text-center">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Top area: Blocks */}
          {/* 1st block */}
          <div className="text-center mb-2">
            {/* Logo */}
            <Link to="/" className="" aria-label="Women Tribe">
              <img
                alt="Women Tribe logo"
                className="inline-block"
                src={require("../images/Logo-big.png").default}
                width={240}
              />
            </Link>
          </div>

          {/* Bottom area */}
          <ul className="my-4 md:order-1 md:ml-4 md:mb-0 space-x-4">
            <li className="inline-block">
              <a
                href="https://twitter.com/WomenTribe_nfts"
                className="flex justify-center items-center text-purple-600 bg-gray-100 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                aria-label="Twitter"
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                </svg>
              </a>
            </li>

            <li className="inline-block">
              <a
                href="https://www.instagram.com/WomenTribe.eth/"
                className="flex justify-center items-center text-purple-600 bg-gray-100 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                aria-label="Instagram"
              >
                <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20.145" cy="11.892" r="1" />
                  <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                  <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
                </svg>
              </a>
            </li>

            <li className="inline-block">
              <a
                href="https://discord.gg/womentribe"
                className="flex justify-center items-center text-purple-600 bg-gray-100 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                aria-label="discord"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M21.5447 10.9947C20.5249 10.5343 19.4313 10.1951 18.2879 10.0008C18.2671 9.99707 18.2463 10.0064 18.2356 10.0252C18.0949 10.2713 17.9392 10.5924 17.8301 10.8448C16.6003 10.6636 15.3768 10.6636 14.1722 10.8448C14.0631 10.5868 13.9017 10.2713 13.7604 10.0252C13.7497 10.0071 13.7289 9.99769 13.708 10.0008C12.5653 10.1945 11.4717 10.5337 10.4513 10.9947C10.4425 10.9985 10.4349 11.0047 10.4299 11.0129C8.35559 14.0621 7.78737 17.0364 8.06612 19.9738C8.06738 19.9881 8.07558 20.0019 8.08693 20.0106C9.45547 20.9995 10.7811 21.5999 12.0822 21.9978C12.103 22.0041 12.1251 21.9966 12.1383 21.9797C12.4461 21.5662 12.7204 21.1301 12.9557 20.6715C12.9695 20.6447 12.9563 20.6128 12.9279 20.6022C12.4928 20.4398 12.0784 20.2417 11.6798 20.0169C11.6483 19.9987 11.6458 19.9544 11.6748 19.9331C11.7586 19.8713 11.8425 19.8069 11.9226 19.742C11.9371 19.7301 11.9573 19.7276 11.9744 19.7351C14.5929 20.9114 17.4277 20.9114 20.0153 19.7351C20.0323 19.727 20.0525 19.7295 20.0677 19.7413C20.1478 19.8063 20.2316 19.8713 20.3161 19.9331C20.3451 19.9544 20.3433 19.9987 20.3117 20.0169C19.9131 20.2461 19.4988 20.4398 19.063 20.6016C19.0346 20.6122 19.022 20.6447 19.0359 20.6715C19.2762 21.1295 19.5505 21.5655 19.8526 21.9791C19.8652 21.9966 19.8879 22.0041 19.9087 21.9978C21.2161 21.5999 22.5417 20.9995 23.9103 20.0106C23.9223 20.0019 23.9298 19.9887 23.9311 19.9744C24.2647 16.5784 23.3723 13.6285 21.5655 11.0135C21.5611 11.0047 21.5535 10.9985 21.5447 10.9947ZM13.3467 18.1852C12.5583 18.1852 11.9088 17.473 11.9088 16.5984C11.9088 15.7238 12.5457 15.0117 13.3467 15.0117C14.1539 15.0117 14.7972 15.7301 14.7846 16.5984C14.7846 17.473 14.1476 18.1852 13.3467 18.1852ZM18.6632 18.1852C17.8748 18.1852 17.2253 17.473 17.2253 16.5984C17.2253 15.7238 17.8622 15.0117 18.6632 15.0117C19.4704 15.0117 20.1137 15.7301 20.1011 16.5984C20.1011 17.473 19.4704 18.1852 18.6632 18.1852Z" />
                </svg>
              </a>
            </li>

            <li className="inline-block">
              <a
                href="https://womentribe.medium.com/"
                className="flex justify-center items-center text-purple-600 bg-gray-100 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out"
                aria-label="discord"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.69565 9L10.087 10.7883V19.2856L8 21.9952H12.8696L10.7826 19.2856V11.722L15.3043 21.9952L15.3037 22L19.4783 11.6499V20.5903L18.087 21.9952H24L22.6087 20.5903L22.5992 10.5819L23.9524 9.0048H19.7724L16.5584 16.9882L13.0421 9H8.69565Z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
