import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import Mission from "../partials/Mission";
import Timeline from "../partials/Roadmap";
import Team from "../partials/Team";
import Footer from "../partials/Footer";
import Faqs from "../partials/Faqs";

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <HeroHome />
        <div className="my-8">
          <img src={require("../images/artwork-banner-1.jpg").default} alt="artwork-1" />
        </div>
        <Mission />
        <Timeline />
        <div className="my-8">
          <img src={require("../images/artwork-banner-2.jpg").default} alt="artwork-2" />
        </div>
        <Team />
        <Faqs />

        {/*  Page sections */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
